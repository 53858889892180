




























import { defineComponent, SetupContext } from '@vue/composition-api'

import { PasswordInputProps } from './PasswordInput.contracts'
import { passwordInputProps, usePasswordInput } from './PasswordInput.hooks'

import OpenEyeIcon from './icons/OpenEyeIcon.vue'
import CloseEyeIcon from './icons/CloseEyeIcon.vue'

/**
 * Component capable to render PasswordInput element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PasswordInput = defineComponent({
  name: 'PasswordInput',
  components: { CloseEyeIcon, OpenEyeIcon },
  props: passwordInputProps,

  setup (props: PasswordInputProps, ctx: SetupContext) {
    return usePasswordInput(props, ctx)
  }
})
export default PasswordInput
